<!--
--------------------------------------------------------------------------------
<copyright file="SalesTopSalespeopleDetailed.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget.top_salespeople') }}</summary>
      <p class="text">{{ $t('widget_info_text.sales_top_salespeople_det_text') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.parameters') }}</summary>
      <p class="text">{{ $t('widget_info_text.sales_top_salespeople_parameters') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.sales_top_salespeople_x_axis') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.sales_top_salespeople_det_bars') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.bars_tip_txt_cat') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
